import React from "react";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  navyBlue,
  mainRed,
  aku,
  innerWidth,
  transHover,
  gotham,
  gothamBold,
  screen,
} from "../components/common/variables";
import Arrow from "../images/svg/right-arrow.svg";
import logo from "../images/tenalphas-moving-content.jpg";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
    font-size: 2.7rem;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    @media ${screen.small} {
      font-size: 2.7rem;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .description {
    margin-top: 30px;
    max-width: 1000px;
    color: ${navyBlue};
    p {
      font-family: ${gotham};
      font-size: 1rem;
      margin-top: 16px;
      @media ${screen.small} {
        font-size: 1.1rem;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    strong {
      font-family: ${gothamBold};
    }
    a {
      color: ${navyBlue};
      font-family: ${gothamBold};
      &:hover {
        text-decoration: underline;
      }
    }

    h1 {
      font-size: 2.2rem;
      font-family: ${gothamBold};
      margin: 10px 0;
    }
    h2 {
      font-size: 2rem;
      font-family: ${gothamBold};
      margin: 10px 0;
    }
    h3 {
      font-size: 1.8rem;
      font-family: ${gothamBold};
      margin: 10px 0;
    }
    h4 {
      font-size: 1.6rem;
      font-family: ${gothamBold};
      margin: 10px 0;
    }
    h5 {
      font-size: 1.4rem;
      font-family: ${gothamBold};
      margin: 10px 0;
    }
    h6 {
      font-size: 1.2rem;
      font-family: ${gothamBold};
      margin: 10px 0;
    }
  }

  .accreditation-container {
    margin-top: 45px;
    @media ${screen.small} {
      margin-top: 90px;
    }

    &__accreditation-list {
      display: flex;
      flex-wrap: wrap;

      @media ${screen.small} {
        margin-left: -20px;
        margin-right: -20px;
      }

      .accreditation {
        margin: 0 0 60px 0;
        @media ${screen.small} {
          width: calc(50% - 40px);
          margin: 0 20px 60px 20px;
        }
        @media ${screen.medium} {
          width: calc(33.33% - 40px);
          margin: 0 20px 60px 20px;
        }

        &__img {
          max-width: 350px;
        }

        &__title {
          color: ${navyBlue};
          font-family: ${gothamBold};
          font-size: 1.2rem;
          margin-bottom: 10px;
          margin-top: 15px;
          padding-right: 30px;
          @media ${screen.small} {
            font-size: 1.3rem;
            margin-bottom: 16px;
            margin-top: 30px;
          }
        }

        &__description {
          color: ${navyBlue};
          max-width: 490px;
          p {
            font-size: 1rem;
            font-family: ${gotham};
            margin-top: 16px;
            @media ${screen.small} {
              font-size: 1.1rem;
            }
            &:first-child {
              margin-top: 0;
            }
          }
          strong {
            font-family: ${gothamBold};
          }
          a {
            color: ${navyBlue};
            font-family: ${gothamBold};
            transition: ${transHover};

            &:hover {
              color: ${mainRed};
            }
          }
          h1 {
            font-size: 2.2rem;
            font-family: ${gothamBold};
            margin: 10px 0;
          }
          h2 {
            font-size: 2rem;
            font-family: ${gothamBold};
            margin: 10px 0;
          }
          h3 {
            font-size: 1.8rem;
            font-family: ${gothamBold};
            margin: 10px 0;
          }
          h4 {
            font-size: 1.6rem;
            font-family: ${gothamBold};
            margin: 10px 0;
          }
          h5 {
            font-size: 1.4rem;
            font-family: ${gothamBold};
            margin: 10px 0;
          }
          h6 {
            font-size: 1.2rem;
            font-family: ${gothamBold};
            margin: 10px 0;
          }
        }
      }
    }
  }
`;

const CredentialsPage = ({ data }) => {
  // Prismic content
  const pageTitle = data.content.data.page
    ? data.content.data.page.text
    : `Credentials`;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `Accreditation`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `Accreditation`;
  const description = data.content.data.description.html;
  const accreditationList = data.content.data.accreditation_list;

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} image={logo} />
      <Wrapper>
        <h2 className="heading">
          <Arrow />
          {pageTitle}
        </h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <div className="accreditation-container">
          <ul className="accreditation-container__accreditation-list">
            {accreditationList.map((item, i) => (
              <li className="accreditation" key={i}>
                <div className="accreditation__img">
                  {item.image.gatsbyImageData && (
                    <GatsbyImage
                      image={item.image.gatsbyImageData}
                      alt={item.image.alt || "Certificate"}
                    />
                  )}
                </div>
                <h3 className="accreditation__title">{item.title}</h3>
                <div
                  className="accreditation__description"
                  dangerouslySetInnerHTML={{ __html: item.description1.html }}
                />
              </li>
            ))}
          </ul>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default CredentialsPage;

export const dataQuery = graphql`
  {
    content: prismicAccreditationPage {
      data {
        title_tag
        meta_description
        description {
          html
        }
        page {
          text
        }
        accreditation_list {
          title
          description1 {
            html
          }
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
